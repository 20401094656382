import * as React from 'react';
import { Placement } from '../placement';

import {
    SearchResultBannersSpacing,
    Props,
} from './search-result-banners-server';
import { useCallback } from 'react';
import { getType } from '../../utils/companion-ready-placement-listener';

const SearchResultBannersClient: React.FC<Props> = (props) => {
    const { cols = 1, deviceType } = props;

    const children = React.Children.toArray(props.children);

    const spacer = useCallback(
        (id, index) => (
            <Placement
                id={id}
                grid={cols !== 1}
                key={id}
                showBanners={true}
                type={getType(index)}
            />
        ),
        [cols],
    );

    return (
        <>
            <SearchResultBannersSpacing
                cols={cols}
                spacer={spacer}
                deviceType={deviceType}
            >
                {children}
            </SearchResultBannersSpacing>
        </>
    );
};

export default SearchResultBannersClient;
