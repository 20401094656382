import * as React from 'react';
import { ErrorBoundary } from '../error-boundary';
import { Placeholder } from '../placeholder';
import { Fallback } from '../fallback';
import { loadCompanionComponent } from '../../utils/component/load';

import { useSearchPositionInstruction } from '../../utils/placement-instruction';
import type { DeviceType } from '../../../shared/types/ast';
import type { SearchSummary } from '../../../shared/types/adsense';

const { useEffect, useState, lazy, Suspense, useCallback } = React;

const LazySearchResultBanners = lazy(() =>
    loadCompanionComponent('SearchResultBanners'),
);

export type SearchResultBannersComponent = React.FunctionComponent<Props>;

export type Props = {
    cols?: 1 | 2 | 3;
    deviceType?: DeviceType;
    searchSummary?: SearchSummary;
    children: React.ReactNode[];
};

const SearchResultBannersLoader: React.FC<Props> = (props) => {
    const { children } = props;

    return (
        <Suspense
            fallback={
                <SearchResultBannersServer {...props}>
                    {children}
                </SearchResultBannersServer>
            }
        >
            <LazySearchResultBanners {...props}>
                {children}
            </LazySearchResultBanners>
        </Suspense>
    );
};

const SearchResultBanners: React.FC<Props> = (props) => {
    const { children } = props;
    const [showBanners, setShowBanners] = useState(false);

    // Wait until after client-side hydration to show banners
    useEffect(() => {
        setShowBanners(true);
    }, []);

    return showBanners ? (
        <SearchResultBannersLoader {...props}>
            {children}
        </SearchResultBannersLoader>
    ) : (
        <SearchResultBannersServer {...props}>
            {children}
        </SearchResultBannersServer>
    );
};

type SpacingProps = {
    spacer: (id: string, index: number) => JSX.Element;
    cols?: 1 | 2 | 3;
    deviceType: DeviceType;
};

export const SearchResultBannersSpacing: React.FC<SpacingProps> = (props) => {
    const { spacer, children, deviceType, cols } = props;

    const searchPosInstruction = useSearchPositionInstruction(cols, deviceType);

    const getChildren = useCallback(() => {
        return React.Children.map(children, (child, childIndex) => {
            const childWithSearchBanner = searchPosInstruction
                ?.filter(({ position }) => position === childIndex)
                .map(({ placementId, advtIndex }) => (
                    <>
                        {spacer(placementId, advtIndex)}
                        {child}
                    </>
                ));

            return childWithSearchBanner?.length > 0
                ? childWithSearchBanner
                : child;
        });
    }, [searchPosInstruction, children, spacer]);

    return <React.Fragment>{getChildren()}</React.Fragment>;
};

const SearchResultBannersServer: React.FC<Props> = (props) => {
    const { cols, deviceType = 'desktop' } = props;
    const children = React.Children.toArray(props.children);

    const spacer = useCallback(
        (id) => (
            <Placeholder id={id}>
                <Fallback id={id} deviceType={deviceType} />
            </Placeholder>
        ),
        [deviceType],
    );

    return (
        <SearchResultBannersSpacing
            cols={cols}
            spacer={spacer}
            deviceType={deviceType}
        >
            {children}
        </SearchResultBannersSpacing>
    );
};

const SearchResultBannersWithErrorBoundary: React.FC<Props> = (props) => {
    return (
        <ErrorBoundary fallback={props.children}>
            <SearchResultBanners {...props}>
                {props.children}
            </SearchResultBanners>
        </ErrorBoundary>
    );
};

export default SearchResultBannersWithErrorBoundary;
