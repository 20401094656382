import Horseshoe from '../../client/components/horseshoe/horseshoe';
import Board from '../../client/components/board/board';
import SearchResultBanners from '../../client/components/search-result/search-result-banners';

export default function init() {
    return {
        Horseshoe: Horseshoe,
        Board: Board,
        SearchResultBanners: SearchResultBanners,
    };
}
