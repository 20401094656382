import * as React from 'react';
import * as Browser from '@podium/browser';
import * as Analysis from '@finn-no/analysis';
import * as Observer from '@finn-no/inscreen-observer';
import { HorseshoeComponent } from '../../components/horseshoe/horseshoe-server';
import { BoardComponent } from '../../components/board/board-server';
import { SearchResultBannersComponent } from '../../components/search-result/search-result-banners-server';

type SupportedComponent = 'Horseshoe' | 'Board' | 'SearchResultBanners';
type SupportedComponentType =
    | HorseshoeComponent
    | BoardComponent
    | SearchResultBannersComponent;

type Components = {
    Horseshoe: HorseshoeComponent;
    Board: BoardComponent;
    SearchResultBanners: SearchResultBannersComponent;
};

declare global {
    interface Window {
        companionQueue?: Function[];
        initializeCompanion: (
            react: typeof React,
            observer: typeof Observer,
            analysis: typeof Analysis,
            browser: typeof Browser,
        ) => Components;
    }
}

function addToCompanionQueue(callback: () => void) {
    window.companionQueue = window.companionQueue || [];
    window.companionQueue.push(callback);
}

let components: Components;

let initialized = false;
function initializeCompanion() {
    if (typeof window !== 'undefined') {
        if (initialized === false) {
            if ('initializeCompanion' in window) {
                components = window.initializeCompanion(
                    React,
                    Observer,
                    Analysis,
                    Browser,
                );
                initialized = true;
            }
        }
    }
}

export function loadCompanionComponent(name: SupportedComponent) {
    initializeCompanion();
    if (components) {
        return new Promise<{ default: SupportedComponentType }>((resolve) => {
            resolve({
                default: components[name],
            });
        });
    }

    return new Promise<{ default: SupportedComponentType }>((resolve) => {
        addToCompanionQueue(function () {
            initializeCompanion();
            resolve({
                default: components[name],
            });
        });
    });
}
